  <!-- 新增诊断工具 -->
<template>
  <div class="add_diagnostic_tool">
    <el-form
      label-width="120px"
      ref="addToolFormRef"
      :model="addToolForm"
      :rules="addToolFormRules"
    >
      <!-- 基本信息 -->
      <div class="basic_info">
        <div class="tittle">基本信息</div>
        <div class="card_body">
          <el-form-item label="诊断工具类型">
            <el-radio-group v-model="addToolForm.evaluationType">
              <el-radio label="1">企业数智化转型诊断评估工具</el-radio>
              <el-radio label="2">企业数智化转型政策申报工具</el-radio>
              <el-radio label="3">调查问卷工具</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="头像" prop="image">
            <div class="img_box">
              <img-big-upload
                :imageShow.sync="addToolForm.image"
                :addTitleShow="false"
                @removeImg="removeImgImage"
                @uploadOneImgShow="uploadOneImgShowImage"
              />
              <span class="color4"
                >此图片应用后台工具列表页，建议上传图片整体尺寸为80*80px，应小于4MB</span
              >
            </div>
          </el-form-item>
          <el-form-item label="诊断工具名称" prop="questionnaireName">
            <div class="input_box">
              <el-input
                v-model="addToolForm.questionnaireName"
                placeholder="请输入诊断工具名称"
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="一句话介绍" prop="toolBrief">
            <div class="input_box">
              <el-input v-model="addToolForm.toolBrief" placeholder="请输入一句话介绍"></el-input>
            </div>
          </el-form-item>
          <el-form-item label="评估依据">
            <div class="input_box">
              <el-input
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 6 }"
                maxlength="300"
                show-word-limit
                v-model="addToolForm.diagnosticBasis"
                placeholder="请输入诊断依据"
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="封面图" prop="coverImg">
            <div class="img_box">
              <img-big-upload
                :imageShow.sync="addToolForm.coverImg"
                :addTitleShow="false"
                @removeImg="removeImgCoverImg"
                @uploadOneImgShow="uploadOneImgShowCoverImg"
              />
              <span class="color4"
                >此图片应用小程序工具列表页，建议上传图片整体尺寸为340*80px，应小于6MB</span
              >
            </div>
          </el-form-item>
          <el-form-item label="适用范围" prop="scopeApplication">
            <div class="input_box">
              <el-input
                v-model="addToolForm.scopeApplication"
                placeholder="请输入适用范围"
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="自诊时间" prop="selfDiagnosisTime">
            <div class="row">
              <div class="mini_input">
                <el-input
                  v-model.number="addToolForm.selfDiagnosisTime"
                  placeholder="请输入数字"
                  oninput="value=value.replace(/^0|[^0-9]/g, '')"
                ></el-input>
              </div>
              <div>
                <span class="color4">分钟</span>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="关联合作伙伴">
            <el-autocomplete
              class="input_box"
              v-model="addToolForm.partnerName"
              :fetch-suggestions="partnerNameQuerySearch"
              placeholder="请输入"
              :trigger-on-focus="false"
              @input="partnerNameChanged"
              @select="partnerNameSelect"
            ></el-autocomplete>
          </el-form-item>
          <el-form-item label="是否获取手机号">
            <el-radio v-model="addToolForm.isPhone" :label="true">是</el-radio>
            <el-radio v-model="addToolForm.isPhone" :label="false">否</el-radio>
          </el-form-item>
          <el-form-item label="参与用户角色">
            <el-checkbox-group v-model="addToolForm.userTypes">
              <el-checkbox v-for="item in userTypeList" :key="item.id" :label="item.id">
                {{ item.name }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </div>
        <!-- <el-form-item label="分析报告">
          <div style="display: flex">
            <div class="mini_input">
              <el-input
                v-model.number="addToolForm.analysisReportStart"
                placeholder="请输入数字"
              ></el-input>
            </div>
            <div class="mini_input">
              <el-input
                v-model.number="addToolForm.analysisReportEnd"
                placeholder="请输入数字"
              ></el-input>
            </div>
            <div>
              <span>小时</span>
            </div>
          </div>
          <div class="row">
            <span class="color4"> (设置用户填写诊断问卷后至分析报告出炉的时间段) </span>
          </div>
        </el-form-item> -->
      </div>
      <!-- 综合评估建议 -->
      <div class="recommend">
        <div class="tittle">综合评估建议</div>
        <div class="card_body">
          <el-form-item label="综合评估建议">
            <div class="recommend_list">
              <div
                class="recommend_item"
                v-for="(item, index) in addToolForm.suggestions"
                :key="index"
              >
                <div class="recommend_item_content">
                  <div class="row">
                    <!-- @change="handleChange" -->
                    <div>
                      <el-input-number
                        v-model="addToolForm.suggestions[index].startingFraction"
                        :min="0"
                        :max="100"
                        :step="10"
                      ></el-input-number>
                    </div>
                    <span>至</span>
                    <div>
                      <el-input-number
                        v-model="item.endFraction"
                        :min="0"
                        :max="100"
                        :step="10"
                      ></el-input-number>
                    </div>
                    <span>分</span>
                  </div>
                  <div class="input_box">
                    <el-input
                      v-model="item.evaluationSuggestion"
                      placeholder="请输入综合评估建议"
                      type="textarea"
                      :autosize="{ minRows: 4, maxRows: 6 }"
                      maxlength="300"
                      show-word-limit
                    ></el-input>
                  </div>
                </div>
                <div class="icon_box">
                  <i class="el-icon-circle-plus" @click="addRecommendItem"></i>
                  <i
                    class="el-icon-remove"
                    v-if="index > 0"
                    @click="deleteRecommendItem(index)"
                  ></i>
                </div>
              </div>
            </div>
          </el-form-item>
        </div>
      </div>
      <!-- 分享链接信息 -->
      <div class="share_link">
        <div class="tittle">分享链接信息</div>
        <div class="card_body">
          <el-form-item label="分享链接标语">
            <div class="input_box">
              <el-input
                v-model="addToolForm.appLinkSlogan"
                placeholder="请输入一句话介绍"
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="分享链接图片" prop="appLinkPicture">
            <div class="img_box">
              <img-big-upload
                :imageShow.sync="addToolForm.appLinkPicture"
                :addTitleShow="false"
                @removeImg="removeImgAppLinkPicture"
                @uploadOneImgShow="uploadOneImgShowAppLinkPicture"
              />
              <span class="color4"
                >(此图片应用小程序分享链接，建议整体尺寸为630*510px，图片应小于6MB)
              </span>
            </div>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <div class="foot_btn_list">
      <el-button type="primary" @click="toManageTroubleGroup">配置问题</el-button>
      <el-button @click="save" :disabled="saveBtnDisabled" plain>保 存</el-button>
    </div>
  </div>
</template>

<script>
import {
  getPartners,
  insertQuestionnaire,
  updateQuestionnaire,
  getQuestionnaireDetail
} from '@/api/diagnoseTool.js'

//新增工具填写的表单
const defaultAddForm = Object.freeze({
  partnerName: '', // 合作伙伴名称
  partnerId: null, // 合作伙伴id
  isPhone: true, // 是否获取手机号
  userTypes: [], // 用户类型
  suggestions: [
    {
      endFraction: 0, // 结束分数
      evaluationSuggestion: '', // 结束分数
      id: null, // 综合评估建议id
      questionnaireId: null, // 工具id
      startingFraction: 0 // 开始分数
    }
  ],
  diagnosticBasis: '',
  appLinkPicture: '',
  appLinkSlogan: '',
  coverImg: '',
  createId: null,
  createTime: '',
  evaluationType: '1',
  id: null,
  image: '',
  isAnalysisChart: '0',
  policyIdList: '',
  questionnaireHint: '',
  questionnaireName: '',
  toolBrief: '',
  updateId: null,
  updateTime: '',
  scopeApplication: '',
  selfDiagnosisTime: null,
  analysisReportStart: null,
  analysisReportEnd: null
})

export default {
  name: 'addDiagnosticTool',
  data() {
    return {
      partnerList: [], // 搜索到的合作伙伴列表
      // 用户类型列表
      // 1:游客用户;2:普通用户;3:企业用户;4:个人会员;5:企业会员;
      userTypeList: [
        {
          id: '1',
          name: '游客用户'
        },
        {
          id: '2',
          name: '普通用户'
        },
        {
          id: '3',
          name: '企业用户'
        },
        {
          id: '4',
          name: '个人会员'
        },
        {
          id: '5',
          name: '企业会员'
        }
      ],
      saveBtnDisabled: false, // 禁用保存按钮
      addToolForm: { ...defaultAddForm }, //新增工具填写的表单
      addToolFormRules: {
        questionnaireName: [
          { required: true, message: '请输入工具名称', trigger: 'blur' },
          { min: 2, message: '请输入2个以上的字符', trigger: 'blur' }
        ],
        toolBrief: [{ required: true, message: '请输入一句话介绍', trigger: 'blur' }],
        scopeApplication: [{ required: true, message: '请输入适用范围', trigger: 'blur' }],
        selfDiagnosisTime: [{ required: true, message: '请输入自诊时间', trigger: 'blur' }],
    
        image: [{ required: true, message: '请上传工具头像', trigger: 'blur' }],
        // appLinkPicture: [{ required: true, message: '请上传分享链接图片', trigger: 'blur' }],
        coverImg: [{ required: true, message: '请上传封面图', trigger: 'blur' }]
      } //新增工具表单验证规则
    }
  },
  created() {
    this.addToolForm = { ...defaultAddForm }
    this.addToolForm.suggestions = [
      {
        endFraction: 0, // 结束分数
        evaluationSuggestion: '', // 结束分数
        id: null, // 综合评估建议id
        questionnaireId: null, // 工具id
        startingFraction: 0 // 开始分数
      }
    ]
    let id = this.$route.query.id
    if (id) {
      this.addToolForm.id = id
      this.search(id)
    }
  },
  methods: {
    async search(id) {
      const { data: res } = await getQuestionnaireDetail({ id })
      if (res.resultCode === 200) {
        this.addToolForm = res.data
        let suggestions = this.addToolForm.suggestions
        if (suggestions && suggestions.length < 1) {
          suggestions.push({
            endFraction: 0, // 结束分数
            evaluationSuggestion: '', // 结束分数
            id: null, // 综合评估建议id
            questionnaireId: null, // 工具id
            startingFraction: 0 // 开始分数
          })
        }
        if (!this.addToolForm.userTypes) {
          this.addToolForm.userTypes = []
        }
        if (!this.addToolForm.coverImg) {
          this.addToolForm.coverImg = ''
        }
        if (!this.addToolForm.image) {
          this.addToolForm.image = ''
        }
        if (!this.addToolForm.appLinkPicture) {
          this.addToolForm.appLinkPicture = ''
        }
      } else {
        this.$message.error('获取工具详细信息失败!')
      }
    },
    //输入框变化
    partnerNameChanged() {
      this.partnerList = []
    },
    //选择合作伙伴
    async partnerNameSelect(item) {
      let keyWord = item.value
      const { data: res } = await getPartners({ keyWord })
      if (res.resultCode === 200) {
        const obj = res.data[0]
        this.addToolForm.partnerName = obj.partnerName
        this.addToolForm.partnerId = obj.id
        this.partnerList = []
      } else {
        this.$message.error('查询失败！')
      }
    },
    //查询合作伙伴
    async partnerNameQuerySearch(str, cb) {
      if (str != '') {
        let keyWord = str
        const { data: res } = await getPartners({ keyWord })
        if (res.resultCode === 200) {
          res.data.forEach((item) => {
            this.partnerList.push({
              value: item.partnerName,
              id: item.id
            })
          })
          cb(this.partnerList)
        } else {
          this.$message.error('查询失败！')
        }
      }
    },
    //删除头像
    removeImgImage(val) {
      this.addToolForm.image = ''
    },
    //添加头像
    uploadOneImgShowImage(val) {
      this.addToolForm.image = val
    },
    //删除封面图
    removeImgCoverImg(val) {
      this.addToolForm.coverImg = ''
    },
    //添加封面图
    uploadOneImgShowCoverImg(val) {
      this.addToolForm.coverImg = val
    },
    //删除分享链接图片
    removeImgAppLinkPicture(val) {
      this.addToolForm.appLinkPicture = ''
    },
    //添加分享链接图片
    uploadOneImgShowAppLinkPicture(val) {
      this.addToolForm.appLinkPicture = val
    },
    //添加选项item
    addRecommendItem() {
      this.addToolForm.suggestions.push({
        endFraction: 0, // 结束分数
        evaluationSuggestion: '', // 结束分数
        id: null, // 综合评估建议id
        questionnaireId: null, // 工具id
        startingFraction: 0 // 开始分数
      })
    },
    //删除选项item
    deleteRecommendItem(index) {
      this.addToolForm.suggestions.splice(index, 1)
    },
    //新增、编辑诊断工具保存
    save(val) {
      const fn = this.addToolForm.id ? updateQuestionnaire : insertQuestionnaire
      const str = this.addToolForm.id ? '编辑诊断工具' : '新增诊断工具'
      this.$refs['addToolFormRef'].validate(async (valid) => {
        if (valid) {
          this.saveBtnDisabled = true
          const { data: res } = await fn(this.addToolForm)
          if (res.resultCode === 200) {
            this.$message.success(str + '成功!')
            if (val !== 1) {
              this.back()
            } else {
              let id = 0
              if (!this.addToolForm.id) {
                id = res.data
              } else {
                id = this.addToolForm.id
              }
              this.$router.push({
                name: 'manageTrouble',
                path: '/diagnosis/managetrouble',
                query: { id }
              })
            }
            this.saveBtnDisabled = false
          } else {
            this.saveBtnDisabled = false
            this.$message.error(str + '失败!')
          }
        } else {
          this.$message.error('必填信息尚未完成，请补充完整')
          return false
        }
      })
    },
    back() {
      // this.$router.go(-1)
      this.$router.push({
        path: '/diagnosis/diagnostictool',
        query: {
          changeNum: true
        }
      })
    },
    //前往问题组管理界面
    toManageTroubleGroup() {
      this.save(1)
      // setTimeout(() => {
      //   this.$router.push({
      //     name: 'manageTrouble',
      //     path: '/diagnosis/managetrouble',
      //     query: { id: this.addToolForm.id }
      //   })
      // }, 500)
    }
  }
}
</script>

<style lang="less" scoped>
.add_diagnostic_tool {
  .basic_info,
  .recommend,
  .share_link {
    display: flex;
    flex-flow: column;
    background-color: #fff;
    margin-bottom: 14px;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 4px;
  }
  .share_link {
    margin-bottom: 0;
  }
  .recommend {
    .recommend_list {
      display: flex;
      flex-flow: column;
      width: 80%;
      .recommend_item {
        display: flex;
        width: 100%;
        .recommend_item_content {
          display: flex;
          flex-flow: column;
          width: 90%;
          border: 0.5px dashed #d4d4d4;
          margin-bottom: 10px;
          padding: 20px;
          box-sizing: border-box;
          .row span {
            color: #999;
            margin: 0 10px;
          }
          .input_box {
            width: 80%;
            .el-textarea {
              width: 100%;
            }
          }
        }
        .icon_box {
          display: flex;
          align-items: center;
          height: 40px;
          i {
            cursor: pointer;
            width: 20px;
            height: 20px;
            font-size: 20px;
            color: #4e93fb;
            margin-left: 10px;
          }
        }
      }
    }
  }
  .tittle {
    font-family: 'PingFang SC';
    font-style: normal;
    font-size: 24px;
    line-height: 34px;
    color: #000;
  }
  .card_body {
    display: flex;
    flex-flow: column;
    padding: 20px;
    box-sizing: border-box;
  }
  .foot_btn_list {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    background-color: #fff;
    border-top: #f6f6f6 1px solid;
  }
  .el-form {
    width: 100%;
    .el-form-item .input_box {
      width: 50%;
      .el-input {
        width: 100%;
      }
    }
  }
  .mini_input {
    width: 120px;
    margin-right: 10px;
    ::v-deep .el-input__inner {
      width: 120px;
    }
  }
  .row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
}
.color4 {
  color: #b8b8b8;
}
</style>
